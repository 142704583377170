//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProgressBar from "@/components/ProgressBar";
import {
    saveInLocalStorage,
    retrieveFromLocalStorage,
    removeFromLocalStorage
} from "@/helpers/local-storage";
import waitForMs from "@/helpers/wait-for-ms";

const SCREENS = Object.freeze({
    CONNECTING: "connecting",
    CONNECTION_ERROR: "connection_error",
    IDLE: "idle",
    IMAGE_REQUEST: "image_request",
    IMAGE_CAPTURED: "image_captured",
    IMAGE_UPLOADING: "image_uploading",
    IMAGE_UPLOAD_SUCCESS: "image_upload_success",
    IMAGE_UPLOAD_ERROR: "image_upload_error",
    DISCONNECTED: "disconnected"
});

export default {
    components: {
        ProgressBar
    },

    data() {
        return {
            at_token: null,
            connected: false,
            document_name: "Unknown",
            document_id: null,

            image_preview_ready: false,
            image_preview_src: "",

            image_file: null,

            image_uploading: false,
            image_upload_progress: 60,

            screen: "connecting",
            SCREENS
        };
    },

    computed: {
        show_action_bar() {
            const AB_HIDDEN_SCREENS = [
                SCREENS.CONNECTING,
                SCREENS.CONNECTION_ERROR,
                SCREENS.IMAGE_UPLOADING,
                SCREENS.DISCONNECTED
            ];
            return AB_HIDDEN_SCREENS.indexOf(this.screen) === -1;
        }
    },

    methods: {
        clearSelectedImage() {
            this.image_preview_ready = false;
        },

        goIdle() {
            this.screen = SCREENS.IDLE;
            this.clearSelectedImage();
        },

        // handler wykonywany w momencie, gdy ze strony PC nadejdzie informacja o zakończeniu sesji (z dowolnego powodu)
        onDisconnect() {
            this.$io.disconnect();
            this.at_token = null;
            removeFromLocalStorage("tpd_token");
            removeFromLocalStorage("tpd_token_exp_date");
            this.screen = SCREENS.DISCONNECTED;
        },

        // wybór pliku do wysłania
        onFileSelect(ev) {
            if (ev.target.files.length != 1) {
                this.clearFileInput();
                return;
            }
            const file = ev.target.files[0];
            if (file.type != "image/jpeg" && file.type != "image/png") {
                this.clearFileInput();
                return this.$message({
                    type: "error",
                    title: "Nieprawidłowy plik",
                    msg: "Dozwolone są tylko pliki JPG i PNG"
                });
            }

            if (file.size > 7340032) {
                this.clearFileInput();
                return this.$message({
                    type: "error",
                    title: "Nieprawidłowy plik",
                    msg: "Maksymalny rozmiar pliku to 7 MB"
                });
            }

            // plik okej - generujemy preview + przekładamy do następnego kroku
            this.image_file = file;
            this.generateImagePreview(file);
            this.screen = SCREENS.IMAGE_CAPTURED;

            this.clearFileInput();
        },
        clearFileInput() {
            this.$refs.file_input.value = "";
        },
        generateImagePreview(file) {
            const READER = new FileReader();
            READER.onload = () => {
                this.image_preview_ready = true;
                this.image_preview_src = READER.result;
            };
            READER.readAsDataURL(file);
        },

        // upload pliku
        async uploadPhoto() {
            if (!this.image_file || this.image_uploading) return;

            this.screen = SCREENS.IMAGE_UPLOADING;
            this.image_upload_progress = 0;

            try {
                const FD = new FormData();
                FD.append("document", this.document_id);
                FD.append("file", this.image_file);

                await this.$axios.$post(`/files`, FD, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "authorization": this.at_token
                    },
                    onUploadProgress: progress => {
                        this.image_upload_progress = Math.ceil(
                            (progress.loaded / progress.total) * 100
                        );
                    }
                });

                this.screen = SCREENS.IMAGE_UPLOAD_SUCCESS;
                this.$io.emit("Remote:image_uploaded");
            } catch (err) {
                console.error(err);
                this.screen = SCREENS.IMAGE_UPLOAD_ERROR;
            }
        },

        // PC prosi o zrobienie zdjęcia
        onPcImageRequest(data) {
            if (data.document == undefined) return;
            this.document_name = data.document.name;
            this.document_id = data.document._id;
            this.screen = SCREENS.IMAGE_REQUEST;
        },

        // TPD odrzuca żądanie zrobienia pliku
        rejectImageRequest() {
            this.$io.emit("Remote:image_request_rejected", {});
            this.document_name = "";
            this.document_id = null;
            this.screen = SCREENS.IDLE;
        },

        // PC daje znać po socketach, że jednak nie chce zdjęcia
        onPcImageRequestCancel() {
            this.document_name = "";
            this.document_id = null;
            this.screen = SCREENS.IDLE;
            this.image_file = null;
            this.image_preview_ready = false;
        },

        // logowanie w socket-io jako TPD
        async onSocketsConnect() {
            this.$io.emit(
                "tpd-login",
                {
                    token: this.at_token,
                    user_agent: window.navigator.userAgent
                },
                _ => {
                    console.log("[Socket.io] " + _.msg);
                    if (_.success === true) {
                        this.connected = true;
                        this.screen = SCREENS.IDLE;
                    } else {
                        this.screen = SCREENS.CONNECTION_ERROR;
                        removeFromLocalStorage("tpd_token");
                        removeFromLocalStorage("tpd_token_exp_date");
                    }
                }
            );
        },
        onSocketsDisconnect() {
            this.connected = false;
            if (this.screen != SCREENS.DISCONNECTED) {
                this.screen = SCREENS.CONNECTING;
            }
        }
    },

    async mounted() {
        await waitForMs(1500);

        // próba pobrania tokenu finalnego z LS
        const cached_1 = retrieveFromLocalStorage("tpd_token");
        const cached_2 = retrieveFromLocalStorage("tpd_token_exp_date");

        // jeżeli nie ma tokenu lub daty lub data jest już miniona (z offsetem godzinnym), to patrzymy za OTP tokenem w linku
        if (!cached_1 || !cached_2 || parseInt(cached_2) <= Date.now() - 1000 * 60 * 60) {
            if (
                !this.$route.query.t ||
                this.$route.query.t == "" ||
                !this.$route.query.rt ||
                this.$route.query.rt == ""
            ) {
                this.screen = SCREENS.CONNECTION_ERROR;
                return;
            }

            // mamy t i rt, staramy się je wymienić na
            try {
                const r = await this.$axios.$post(
                    "/auth/device-token",
                    {
                        token: this.$route.query.t,
                        ref: this.$route.query.rt
                    },
                    {
                        supress_errors: true
                    }
                );

                this.at_token = r.token;
                saveInLocalStorage("tpd_token", r.token);
                saveInLocalStorage("tpd_token_exp_date", r.token_exp_date);

                return this.$router.replace({
                    query: {}
                });
            } catch (err) {
                console.error(err);
                this.screen = SCREENS.CONNECTION_ERROR;
                return;
            }
        } else {
            this.at_token = cached_1;
        }

        // jeżeli tu dotarliśmy to mamy valid token
        this.$io.on("connect", this.onSocketsConnect);
        this.$io.on("disconnect", this.onSocketsDisconnect);
        this.$io.on("Local:disconnected", this.onDisconnect);
        this.$io.on("Local:request_image", this.onPcImageRequest);
        this.$io.on("Local:image_request_canceled", this.onPcImageRequestCancel);

        if (!this.$io.connected) {
            this.$io.connect();
        } else {
            this.onSocketsConnect();
        }
    },

    beforeDestroy() {
        this.$io.off("connect", this.onSocketsConnect);
        this.$io.off("disconnect", this.onSocketsDisconnect);
        this.$io.off("Local:disconnected", this.onDisconnect);
        this.$io.off("Local:request_image", this.onPcImageRequest);
        this.$io.off("Local:image_request_canceled", this.onPcImageRequestCancel);
    }
};
