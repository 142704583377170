//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatPrice } from "@/helpers/format-price";

export default {
    props: {
        pp: {
            type: String
        }
    },

    data() {
        return {
            loading: true,
            payment_state: "created", // od tego zależy co się w widoku wyświetli
            payment: null,
            tpay_payment_channels: null,
            application_payments_count: 1,
            current_payment_number: 1,

            selected_tpay_payment_channel: null,
            tpay_tos_accepted: false,
            supping_payment: false,

            formatPrice
        };
    },

    methods: {
        redirectToTpay() {
            if (!this.payment)
                return this.$message({
                    type: "error",
                    msg: "Could not redirect to tpay"
                });

            window.location.href = this.payment.payment_intent.tpay_payment_url;
        },

        async createPaymentIntent() {
            if (this.supping_payment) return;

            const a = [this.$refs.tpay_tos.validate()];
            if (a.indexOf(false) !== -1) {
                return;
            }

            if (this.selected_tpay_payment_channel == null) {
                return this.$message({
                    type: "error",
                    title: "Brak danych",
                    msg: "Proszę wybrać metodę płatności"
                });
            }

            this.supping_payment = true;

            try {
                const r = await this.$axios.$post(
                    `/payments/pb/${this.payment._id}/tpay-payment-channel`,
                    {
                        hash: this.$route.query.ph,
                        tpay_payment_channel: this.selected_tpay_payment_channel
                    }
                );
                this.payment.payment_intent = {
                    tpay_payment_url: r.tpay_redirect_url
                };
                this.payment.status = "pending";

                this.payment_state = "pending";
                this.$parent.show_tpay_banner = false;
                setTimeout(this.redirectToTpay, 3000);
            } catch (err) {
                console.error(err);
            }

            this.supping_payment = false;
        }
    },

    async mounted() {
        if (!this.$store.state.initial_layout_set) return;

        const PID = this.$route.query.py;
        const PHASH = this.$route.query.ph;
        if (!PID || !PHASH) {
            this.loading = false;
            this.payment_state = "error";
            return;
        }

        try {
            const r = await this.$axios.$get(`/payments/pb/${PID}?hash=${PHASH}`);
            this.payment = r.payment;

            if (r.payment.status == "pending" && r.payment.payment_intent != undefined) {
                this.payment_state = "pending";
                setTimeout(this.redirectToTpay, 3000);
            } else if (r.payment.status == "initiated") {
                this.tpay_payment_channels = r.tpay_payment_channels;
                this.application_payments_count = r.application_payments_count;
                this.current_payment_number = r.current_payment_number;
                this.payment_state = "choose-channel";
                this.$parent.show_tpay_banner = true;
            } else {
                this.payment_state = "error";
            }

            this.loading = false;
        } catch (err) {
            console.error(err);
        }
    },

    metaInfo: {
        title: "Opłać wniosek"
    }
};
