/**
 * 
 * @param {Integer} number Cena w groszach
 * @return {String} Sformatowana liczba 
 */
export function formatPrice(number){
    if(number == undefined) return '';
    if(typeof number !== 'string') number = number.toString();
    while(number.length < 3) number = '0' + number;
    const decimals = number.slice(-2);
    let rest = number.slice(0, -2);
    const rest_groups = [];
    while (rest.length > 0){
        rest_groups.unshift(rest.slice(-3));
        rest = rest.slice(0, -3);
    }
    
    return rest_groups.join(' ') + ',' + decimals;
}